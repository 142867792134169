import request from '@/utils/request';

// 列表
export const socket = (params) => {
    const rand = Date.now();
    return request.get(`/devices/socket?${rand}`, { params });
}

// 列表
export const information = (params) => {
    const rand = Date.now();
    return request.get(`devices/socket/information?${rand}`, { params });
}

// 踢除网关
export const eliminate = (params) => {
    const rand = Date.now();
    return request.get(`devices/eliminate?${rand}`, { params });
}

export default {
    socket,
    information,
    eliminate
}