<template>
  <div v-loading="loading.global">
    <div class="top-search-box p-b-0" style="padding-top: 0px !important;">
      <el-form ref="search" :model="searchParams" label-width="120px">
        <el-row>
          <el-col :span="5">
            <el-form-item label="设备编号">
              <el-input v-model="searchParams.code" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
            </el-form-item>
          </el-col>
          <el-col :span="19" class="right-btns">
            <div style="padding-top:5px;">
              <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-table :data="dataList" style="width: 100%;" :height="height - 50"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }"
      >
        <el-table-column label="设备编号" prop="key" align="center"></el-table-column>
        <el-table-column label="IP" prop="ipAddress" align="center"></el-table-column>
        <el-table-column label="端口号" prop="port" align="center"></el-table-column>
        <el-table-column label="操作" prop="port" align="center" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="eliminate(scope.row.key)">踢除网关</el-button>
            <el-button size="mini" type="primary" @click="getInformation(scope.row.port)">查看信息</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title=" 网管连接信息" destroy-on-close :close-on-press-escape="false" show-close
        :close-on-click-modal="true" v-if="dialogVisible" :visible="dialogVisible"
        @close="dialogVisible = false" width="600px">
        <div v-loading="loading.information">
          {{ information }}
        </div>
      </el-dialog>
  </div>
</template>
<script>
import gwInfoApi from "@/api/gw-info";

export default {
  data () {
    return {
      loading: {
        global: false,
        information: false
      },
      searchParams: {
        code: ""
      },
      dataList: [],
      protoDataList: "",
      dataMap: {},
      height: 0,
      information: "",
      dialogVisible: false
    }
  },
  async created () {
    this.height = document.body.scrollHeight - 100;
    this.getDatas();
  },
  methods: {
    onSearch () {
      if (this.searchParams.code) {
        const data = this.dataMap[this.searchParams.code];
        this.dataList = [ data ];
      } else {
        this.getDatas();
      }
    },
    async getDatas () {
      try {
        this.loading.global = true;
        const res = await gwInfoApi.socket();

        const dataMap = {};
        for (const obj of res) {
          dataMap[obj.key] = obj;
        }

        this.dataMap = dataMap;
        this.protoDataList = JSON.stringify(res);
        this.dataList = res;
      } catch (err) {
        this.dataMap = {};
        this.dataList = [];
      } finally {
        this.loading.global = false;
      }
    },
    async getInformation (port) {
      try {
        this.dialogVisible = true;
        this.loading.information = true;
        const params = {  port };

        const information = await gwInfoApi.information(params);

        this.information = information[port];
      } finally {
        this.loading.information = false;
      }
    },
    async eliminate (key) {
      try {
        const params = {
          code: key
        };

        await gwInfoApi.eliminate(params);
      } finally {
        this.$message.success(`设备“${key}”已被踢除。`);
        this.getDatas();
      }
    }
  }

}
</script>